import { Button, HeroLegacy as Hero } from "@3beehivetech/components";
import { GatsbyImage, getImage } from "gatsby-plugin-image";
import React, { useContext } from "react";
import styled from "styled-components";
import Link from "../components/Link/Link";
import { DataContext } from "../contexts/DataContext";

const StyledNotFoundPage = styled.div`
  & div {
    mask-image: none;
  }
`;

const NotFoundPage = () => {
  const { pageLabels, pageImages } = useContext(DataContext);
  const imageData = pageImages.find(e => e.path === "/2020/images/business/beenergy/hero_bee.jpg");

  /// Workaround for production build
  if (pageLabels === undefined) {
    return null;
  }

  return (
    <StyledNotFoundPage>
      <Hero
        title={
          <div style={{ marginTop: "0vh", textTransform: "uppercase" }} dangerouslySetInnerHTML={{ __html: pageLabels.hero.title }}/>
        }
        paragraph={pageLabels.hero.paragraph}
        headerButtonComponent={
          <Link to="/">
            <Button>{pageLabels.hero.cta}</Button>
          </Link>
        }
        headerVariant="transparent"
        backgroundImageComponent={
          <GatsbyImage loading="eager" layout="fullWidth" objectFit="cover" objectPosition="bottom" style={{
            height: "100%",
            width: "100%",
          }} image={getImage(imageData)} alt={imageData.alt} />
        }
      />
    </StyledNotFoundPage>
  );
};

export default NotFoundPage;
